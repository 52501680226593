const GLOBAL = {
	screen: {
		md: window.matchMedia('(max-width: 1180px)').matches,
	},
	context: {
		/* insert django context here */
	},
	debounce(func, delay) {
		let timeoutId
		return function (...args) {
			const context = this
			if (timeoutId) clearTimeout(timeoutId)
			timeoutId = setTimeout(() => {
				func.apply(context, args)
			}, delay)
		}
	},
}

window.GLOBAL = GLOBAL
